<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
     style="background-color: white !important; color: black !important;"
  >
    <CSidebarBrand class="d-md-down-none" to="/" style="background-color: #111 !important;">
      <div style="margin: auto;"><img src="/main_logo.jpeg" style="width: 150px; margin: 15px;"/></div>
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="menu"/>
  </CSidebar>
</template>

<script>
import ws from '../services/general';
export default {
  name: 'TheSidebar',
  data: function () {
		return {
        menu: []
    }
  },
  mounted: async function() {
      let menu = [];

      let response = await ws.getMenu("sitebar"); 

      if(response.type == "success"){
        menu = response.data;

        response.data.forEach(item => {
            if(item.is_grouper){
                menu.push({_name: 'CSidebarNavTitle', _children: [item.label]});
            }
            else{
              menu.push({_name: 'CSidebarNavItem',name: item.label, to: item.target, icon: item.icon});
            }
        });
      }

      this.menu = [{
        _name: 'CSidebarNav',
        _attrs: { style: 'color: #000;' },
        _children: menu
      }];
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>

